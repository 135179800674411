import logo from './logo.svg';
import './App.css';
// import './post'
import Posts from './posts'
// import Login from './login'
// import Signup from './singup'
import { Router } from "@reach/router";

function App() {
  return (
    // <div className="App">
      // {/* <header className="App-header"> */}
      //   {/* <img src={logo} className="App-logo" alt="logo" /> */}
      //   {/* <p>
      //     Edit <code>src/App.js</code> and save to reload.
      //   </p>
      //   <a
      //     className="App-link"
      //     href="https://reactjs.org"
      //     target="_blank"
      //     rel="noopener noreferrer"
      //   >
      //     Learn React
      //   </a> */}
      // {/* </header> */}
    // </div>
    <Router>
      <Posts path="/posts" />
      {/* <Login path="/login" />
      <Signup path="/signup" /> */}
      {/* <Post path="/posts/:id" /> */}
    </Router>
  );
}

export default App;
