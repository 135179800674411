import React, { useEffect, useState } from "react";

function getOrigin() {
    return window.location.origin;
}

class Posts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
        };
    }

    handleSubmit = async(event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        
        const postData = {
            title : formData.get("title"),
            username : formData.get("username"),
            content : formData.get("content"),
            origin: getOrigin(),
        };
        const resp = await fetch(
            "https://intern-assignment.bitra2.workers.dev/posts", {
                method: "POST",
                headers: {
                    // 'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData),
                credentials: 'include'
        });
        if(resp.status != 200) {
            alert("Error: " + resp.statusText);
        }
        if(resp.status == 200) {
            alert("Success! Get posts to see updated view!");
        }
    }

    handleGet = async(event) => {        
        event.preventDefault();
        const resp = await fetch("https://intern-assignment.bitra2.workers.dev/posts?origin=" + getOrigin());
        this.setState({
            posts: await resp.json(),
        });
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <label>Title:</label>
                    <input type="text" name="title" placeholder="title" />
                    <label>Username:</label>
                    <input type="text" name="username" placeholder="title" />
                    <label>Content:</label>
                    <input type="text" name="content" placeholder="title" />
                    <input type="submit" />
                </form>
                <h1>Posts</h1>
                {this.state.posts.map((post) => (
                    <div>
                        {post}
                    </div>
                ))}
                <button onClick={this.handleGet}>Get Posts</button>
            </div>
        );
    }
}

//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     const getPosts = async () => {
//         const postData = {
//             title = "",
//             username = "",
//             content = "",
//         };
//         const resp = await fetch(
//         "http://127.0.0.1:8787/posts", {
//             method: "GET",
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: postData
//         }
//       );

//       const postsResp = await resp.json();
//       setPosts(postsResp);
//     };

//     getPosts();
//   }; //, []);

//   return (
//     <div>
//         <form>
//             <input type="text" name="title" placeholder="title" />
//         </form>
//         <h1>Posts</h1>
//         {posts.map((post) => (
//             <div>
//                 {post}
//             </div>
//         ))}
//     </div>
//   );
// };

export default Posts;